import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

// enable Stimulus debug mode in development
if (process.env.NODE_ENV === 'development') {
  window.stimulusApplication = application
  // If you want to debug stimulus actions, uncomment the next line
  // application.debug = true
  // OR run in console:
  // stimulusApplication.debug = true
}
