import './views/show'
import './views/metrics'
import './views/sales_by_local'
import './views/index'
import './views/sales_evolution'
import './views/sales_by_typology'
import './views/sales_by_discount'
import './views/sales_by_local'
import './views/sales_by_pos'
import './views/dynamic_forms'
