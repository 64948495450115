import EventListFilters from '../partials/event_list_filters'
import NonBlockedOccupationLogic from '../partials/non_blocked_occupation_logic'
import {eventsMultiSelectUpdater} from "../../../eventsMultiSelectUpdater";
import setupTogglers from '../../../services/events_accordion'

$(document).on('turbolinks:load', () => {
  window.onpopstate = function (event) {
    if (event && event.state) {
      window.location.reload()
    }
  }

  if ($('#events-index').length !== 0) {

    // Setup clearable search
    $('#name_filter .input--clean').get(0).onclick = () => {
      $("#name_filter input[name='term']").val('')
    }
  }

  // Define advanced search toggle
  $('#advanced-search-dropdown').on('click', function () {
    $(this).toggleClass('opened')
    if ($(this).hasClass('opened')) {
      $('#advanced-search-filters-group').addClass('filters_events__opened')
    } else {
      $('#advanced-search-filters-group').removeClass('filters_events__opened')
    }
  })

  $(document).on('events-list-loaded', () => {
    new EventListFilters().setup()
    new NonBlockedOccupationLogic().setup()
    setupSelectTargets()
    setDataPrevious()
    setupTogglers()

    // If filters exist set to url
    if ($('[name="url_filters"]').val()) {
      // Url path host + subpages
      const urlPath = window.location.origin + window.location.pathname + '?' + $('[name="url_filters"]').val()
      // Push to history
      const url = new URL(urlPath)
      window.history.pushState({}, '', url)
    }
  })

  function setupSelectTargets() {
    const selectTargets = $('[select2-target]')
    selectTargets.select2({
      width: '100%',
    })
    for (const target of selectTargets){
      eventsMultiSelectUpdater(target);
    }
  }

  function setDataPrevious() {
    const selectTargets = $('[select2-target]:not([select2-target="event_status"])')
    if (selectTargets.length){
      selectTargets.each(function(_, element) {
        const target = $(element)
        if (!target.val().length){
          target.val(null).trigger('change.select2')
        }
        target.data('data-prev', target.val())
        onSelectingTarget(target)
      });
    }
  }

  function onSelectingTarget(selector) {
    // Context filter on change
    selector.on('select2:selecting', (ev) => {
      const options = Array.from(selector.get(0).options)
      const previousOption = Array.from(selector.get(0).selectedOptions)?.map(option => option.value)
      const selectedOption = ev.params.args.data.id
      let newOptions = []

      if (selectedOption === '') {
        newOptions = ['']
      } else if (selectedOption !== '') {
        if (!previousOption.includes('')) {
          newOptions = previousOption
        }
        options.forEach((element) => {
          if (element.value.includes(selectedOption)) {
            newOptions.push(element.value)
          }
        })
      }
      selector.val(newOptions).trigger('change.select2')
    })
  }
})
