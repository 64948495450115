import 'chartkick/highcharts'
import Highcharts from 'highcharts'
import i18n from './i18n'

Highcharts.setOptions({
  lang: {
    months: i18n.t('date.month_names').slice(1),
    shortMonths: i18n.t('date.abbr_month_names').slice(1),
    weekdays: i18n.t('date.day_names'),
    shortWeekdays: i18n.t('date.abbr_day_names')
  },
  time: {
    useUTC: false,
    timezone: 'Europe/Lisbon'
  }
})
