import { setupPasswordVisibility } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#reset-password-form').length !== 0) {
    const passwordSelector = document.querySelector('[name="password"]')
    const passwordIcon = passwordSelector.parentElement.querySelector('[selector="change-visibility"]')
    setupPasswordVisibility(passwordIcon, passwordSelector)

    const confirmPasswordSelector = document.querySelector('[name="confirm_password"]')
    const confirmPasswordIcon = confirmPasswordSelector.parentElement.querySelector('[selector="change-visibility"]')
    setupPasswordVisibility(confirmPasswordIcon, confirmPasswordSelector)
  }
})
