/* global $ */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    var chart_container = document.getElementById("tickets-chart-container_0");
    chart_container.style.display = "block";
  }

  changeChartDisplay(event){
    const container_id = "tickets-chart-container_"
    const sessions_length = event.target.dataset.length;
    const buttonId = event.target.id;
    const buttonIdAsNumber = parseInt(buttonId.match(/\d+$/)[0]);

    for (let index = 0; index < sessions_length; index++) {
      var chart_container = document.getElementById(container_id + index);
      
      if(index == buttonIdAsNumber)
        chart_container.style.display = "block";
      else
        chart_container.style.display = "none";
    }
  }
}
