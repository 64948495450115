$(document).on('turbolinks:load', () => {
  if ($('#management-index').length !== 0 || $('#management-invite-user').length !== 0) {
    // Setup user type selector
    const userTypeElement = $("[select2-target='user_types']")
    userTypeElement.val('').trigger('change')
    userTypeElement.select2({
      multiple: false,
      width: '100%'
    })

    let oldVal = ''
    userTypeElement.on('change', () => {
      const type = userTypeElement.val()

      $('.mngmt_type').hide() // Hide all before showing the desired ones

      if (type === 'guest') {
        $('#mngmt_type_guest').show()
        if (oldVal !== 'guest') {
          // Clear other values
          $('#mngmt_type_entities').children('[data-controller]').get(0)?.controller?.clearList()
        }
      } else {
        if (oldVal === 'guest') {
          // Clear other values
          $('#mngmt_type_guest').children('[data-controller]').get(0).controller.clearList()
        }
        $('#mngmt_type_entities')?.show()
      }

      oldVal = type
    })

    // Set initial value to empty if there is not previous data
    if (userTypeElement.attr('selected-value')) {
      userTypeElement.val(userTypeElement.attr('selected-value')).trigger('change')
    }

    // Events selector is setup on multiple select2 controller

    // Set password toggler
    const $passwordInput = $('#password').first()
    const $passwordVisibilityIcon = $passwordInput.parent().find('.sessions__field--icon').first()

    if ($passwordVisibilityIcon.get(0)) {
      $passwordVisibilityIcon.get(0).onclick = () => {
        if ($passwordInput.attr('type') === 'password') {
          // Password visible
          $passwordInput.attr('type', 'text')
          $passwordVisibilityIcon.removeClass('fa-eye')
          $passwordVisibilityIcon.addClass('fa-eye-slash')
        } else {
          // Password hidden
          $passwordInput.attr('type', 'password')
          $passwordVisibilityIcon.removeClass('fa-eye-slash')
          $passwordVisibilityIcon.addClass('fa-eye')
        }
      }
    }
  }

  $('.management__scroll-down').click(() => {
    $('html, body').animate({ scrollTop: $('#invite_manager').offset().top - 60 }, 'slow')
  })
})
