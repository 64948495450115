export default () => {
  // Set sales evolution chart properties
  $(document).on('sales_evolution_loaded', _ => {
    const $switchTotal = $("[selector='sales_evolution_checkbox_total']")
    $switchTotal.on('change', _ => {
      // Display loading spinner before the chart loads
      $('#sales_evolution_by_event_chart_container > #sales_evolution_container_').css('display', 'block')

      // Send event to start rendering (only the first time)
      // This is needed to avoid triggering other render_asyncs, as it should have it own event
      // It also needs bubbles to trigger render_async
      if ($('#sales_evolution_container_by_event').length === 0) {
        $('#sales_evolution_by_event_chart_container').get(0).dispatchEvent(new Event('async-sales-evolution', { bubbles: true }))
      }

      $('#sales_evolution_total_chart_container').css('display', 'none')
      $('#sales_evolution_by_event_chart_container').css('display', 'block')
      $switchTotal.prop('checked', false)
    })
  })

  $(document).on('sales_evolution_by_event_loaded', _ => {
    const $switchByEvent = $("[selector='sales_evolution_checkbox_by_event']")
    $switchByEvent.on('change', _ => {
      $('#sales_evolution_total_chart_container').css('display', 'block')
      $('#sales_evolution_by_event_chart_container').css('display', 'none')
      $switchByEvent.prop('checked', true)
    })
  })
}
