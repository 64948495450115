import Base from '../../base'
class MetricsFilters extends Base {
  setup () {
    $("#metrics_spinner").hide()
    // Location
    $('#location_id').on('change', e => {
      this.disableInput(true)
      const eventId = document.getElementById('event_id').value
      const startDate = document.getElementById('start_date').value
      const endDate = document.getElementById('end_date').value

      const metricsFilters = this
      $.ajax({
        url: '/select2/event_location_dates',
        type: 'GET',
        data: { event_id: eventId, location_id: e.target.value, start_date: startDate, end_date: endDate },
        success: function (data) {
          document.getElementById("session_metrics_calendar").dataset.availableDates = JSON.stringify(data.results)
          // Clear value from select2
          $('#session_time').val(null).trigger('change')
          $('#refresh_input').trigger('click')
          metricsFilters.disableInput(false)
        }
      })
    })
    // No date calendar selected change data and empty options
    $('#session_time').on('select2:open', () => {
      const sessionDate = document.getElementById('session_date').value
      if (!sessionDate) {
        const locationId = document.getElementById('location_id').value
        const eventId = document.getElementById('event_id').value

        document.querySelector("#session_time").dataset.ajaxData = JSON.stringify({
          event_id: eventId,
          location_id: locationId,
          is_all_sessions: false,
          selected_session: null
        })
      }
    })
    // On selection
    $('#session_time').on('select2:select', e => {
      // Load spin
      $("#metrics_spinner").show()

      const option = e.params.data.id
      if (/^[0-9][0-9]:[0-5][0-9]$/.test(option)) {
        document.getElementById("is_all_sessions_time").value = false
      } else {
        document.getElementById("is_all_sessions_time").value = true
      }
      // HACK: Change value from select2
      $('#session_time').val(option).trigger('change')
      // Submit form on select
      $('#metrics_form').trigger('submit.rails')

      // Disable after submit
      this.disableInput(true)
      $("#metrics_stats").hide()
      $("#metrics_cards").hide()
    })
  }
  // Disable filters
  disableInput (isDisabled) {
    document.getElementById('location_id').disabled = isDisabled
    document.getElementById('session_time').disabled = isDisabled
    if (isDisabled){
      for (const select of document.querySelectorAll('[input_clearable="true"]')) {
        select.setAttribute('disabled', 'disabled')
        $(select).parent().find('.styled_select').attr('disabled', 'disabled')
      }
    } else {
      for (const select of document.querySelectorAll('[input_clearable="true"]')) {
        select.removeAttribute('disabled')
        $(select).parent().find('.styled_select').attr('disabled', false)
      }
    }
  }
}

export default MetricsFilters
