import EventSessions from '../partials/event_sessions'
import { setupTableStickyHeaders, setupBreadcrumbsSticky } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#events-sales-by-pos').length !== 0) {
    new EventSessions().setup()

    // Setup select2
    $('[select2-target="sale-place-selector"]').select2({
      width: null
    })
    setupTableStickyHeaders()
    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
