import Base from '../../base'
import { setupChartLegendHover } from '../../../services'

class SalesByPosChart extends Base {
  setup () {
    setupChartLegendHover('desktop_sales_by_pos_chart')
    // setupChartLegendHover('mobile_sales_per_pos_card')
  }
}

export default SalesByPosChart
