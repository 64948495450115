/* global $ */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
  }

  toggle () {
    const menu = this.element.querySelector('.list-dropdown-menu')
    const arrow = this.element.querySelector('.fa-chevron-down')

    if (menu.style.maxHeight) {
      menu.style.maxHeight = null
      menu.style.borderTop = 'none'
      arrow.style.transform = 'rotate(0deg)'
    } else {
      menu.style.maxHeight = menu.scrollHeight + 'px'
      menu.style.borderTop = '1px solid #edecec'
      arrow.style.transform = 'rotate(180deg)'
    }
  }
}
