import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { tour: Object }

  name = this.tourValue.tourName
  tourStops = this.tourValue.tourStops
  $overlay = null
  $modal = null
  $container = $('.manager__container')
  barHeight = $('.manager-nav').outerHeight()
  steps = this.tourValue.steps
  index = this.tourValue.initialStep
  initialStep = this.tourValue.initialStep
  step = this.initialStep
  tourOnload = this.tourValue.tourOnload
  canContinue = true

  connect () {
    // TODO: When we have the user first time show dashboard_initial_tutorial
      if (this.tourOnload){
        this.start()
      }
  }

  start () {
    this.index = this.initialStep
    this.step = this.initialStep
    this.$container.addClass('onboarding')
    $('html').animate({ scrollTop: 0 })
    this.render(this.getTourStop())
  }

  stop () {
    if (this.$overlay != null) this.$overlay.remove()
    if (this.$modal != null) this.$modal.remove()

    this.$overlay = null
    this.$modal = null
    this.$container.removeClass('onboarding')
    this.$container.removeClass((_index, css) =>
      (css.match(/\bonboarding\S+/g) || []).join('')
    )
    // Remove all onboardings
    $("[class*='onboarding--']").removeClass((_index, className) => 
      (className.match (/\bonboarding--\S+/g) || []).join(' ') )
    $('html').animate({ scrollTop: 0 })
    $('body').removeClass('no-scroll')
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  next () {
    const tourStop = this.getTourStop(true)
    this.render(tourStop)
  }

  render (tourStop) {
    if (!tourStop) {
      this.stop()
    } else {
      this.renderOverlay()
      this.updateCheckpoints(tourStop)
      this.updateScroll(tourStop)
      this.renderCard(tourStop)
      this.click(tourStop)
    }
  }

  renderCard (tourStop) {
    const $modalBox = $('<div class="modal-window__box"></div>')
    if (tourStop.size) $modalBox.addClass('modal-window__box--small')
    const $title = $(
      `<h1 class="text--dark--gray heading-xs text-bold margin-bottom">${tourStop.title}</h1>`
    )
    const $messages = tourStop.messages.map((message) =>
      $(`<p class="text--dark--gray text-small margin-bottom">${message}</p>`)
    )
    const $progressBox = $('<div class="progress-box"></div>')
    const $progress = $('<div class="progress"></div>')
  
    for (let i = 0; i < this.steps; ++i) {
      if (this.step === i) {
        $progress.append(
          "<span class='progress-dot progress-dot__current'></span>"
        )
      } else {
        $progress.append(
          "<span class='progress-dot progress-dot__step'></span>"
        )
      }
    }

    if (this.name !== 'dashboard_initial_tutorial') {
      const $exit = $('<i class="fal fa-times text-base exit_button"></i>')
      $exit.get(0).onclick = this.stop.bind(this)
      $modalBox.append($exit)
    }

    const $button = $(
      `<button id="tour-apply" class="primary_manager_button small_button">${tourStop.buttonText}</button>`
    )

    if (!this.canContinue) $button.hide()
    else $button.show()

    if (tourStop.buttonLink) {
      $button.get(0).onclick = () =>
        window.location.replace(tourStop.buttonLink)
    } else {
      $button.get(0).onclick = this.next.bind(this)
    }

    $progressBox.append($progress, $button)
    $modalBox.append($title, $messages, $progressBox)

    this.renderModal($modalBox, tourStop)
  }

  renderOverlay () {
    if (this.$overlay == null) {
      this.$overlay = $('<div></div>')
      $('body').append(this.$overlay)
    }

    this.$overlay.attr('class', 'overlay-dark-background')
  }

  renderModal ($modalBox, tourStop) {
    if (this.$modal == null) {
      this.$modal = $('<div class="modal-window"></div>')
      $('body').append(this.$modal)
    } else {
      this.$modal.removeClass((_index, css) => (css.match(/\bmodal-tutorial\S+/g) || []).join(''))
    }

    if (tourStop.target) this.$modal.addClass('modal-tutorial--' + tourStop.target)

    this.$modal.empty()
    this.$modal.append($modalBox)
  }

  getTourStop (next = false) {
    if (next) {
      this.index += 1
      this.step += 1
    }
    if (!this.tourStops || this.tourStops === [] || this.index >= this.tourStops.length) return null

    const tourStop = this.tourStops[this.index]

    return tourStop
  }

  updateCheckpoints (tourStop) {
    $("[class*='onboarding--']").removeClass((_index, className) => 
      (className.match (/\bonboarding--\S+/g) || []).join(' ') )

    if (tourStop && tourStop.checkpointTarget) {
      let $checkpointTarget = $(`[checkpoint-target='${tourStop.checkpointTarget}']`)
      $checkpointTarget.addClass('onboarding--' + tourStop.checkpointTarget)
      $('body').css('background-color', 'black')

      if (tourStop.checkpointTarget === 'home') {
        document.querySelector('.sidebar').classList.add('clicked')
      } else if (tourStop.checkpointTarget === 'guest_chosen') {
        $('#manager_kind').val(3)
        $('#manager_kind').css('color', 'black')
        $('#mngmt_kind_guest').show()
      } else if (tourStop.checkpointTarget === 'event_type') {
        if ($(window).width() <= 600) {
          $('.onboarding--event_type .event_type > div > div').css({
            cursor: 'pointer',
            animation: 'pulse 2s infinite',
            'box-shadow': '0 0 0 rgb(186, 216, 59)',
            'background-color': 'white',
            'z-index': '9999',
            'padding-top': '20px',
            'padding-left': '20px'
          })
        } else {
          $('.onboarding--event_type .event_type > div').css({
            cursor: 'pointer',
            animation: 'pulse 2s infinite',
            'box-shadow': '0 0 0 rgb(186, 216, 59)',
            'background-color': 'white',
            'z-index': '9999',
            'padding-top': '20px',
            'padding-left': '20px'
          })
        }
      } else if (tourStop.checkpointTarget === 'cards') {
        $('.event_type > div').removeAttr('style')
        $('.event_type > div > div').removeAttr('style')
      }

      // If the checkpoint target does not exist, we may need to wait for the render_async
      if (tourStop.checkpointEvent) {
        $(document).on(tourStop.checkpointEvent, () => {
          let $checkpointTarget = $(`[checkpoint-target='${tourStop.checkpointTarget}']`)
          $checkpointTarget.addClass('onboarding--' + tourStop.checkpointTarget)
        })
      }
    }
  }

  updateScroll (tourStop) {
    let scrollTop = 0
    const height = $(window).height()
    if (tourStop.scrollTarget) {
      let $scrollTarget = $(`[scroll-target='${tourStop.scrollTarget}']`)
      const scrollToTarget = (scrollTarget) => {
        this.canContinue = true
        $('#tour-apply').show()

        let $scrollTarget = $(`[scroll-target='${scrollTarget}']`)

        const $desktopScrollTarget = $(`[scroll-target='${scrollTarget}-desktop']`)
        const $mobileScrollTarget = $(`[scroll-target='${scrollTarget}-mobile']`)
  
        if ($(window).width() <= 600 && $mobileScrollTarget.length != 0) {
          $scrollTarget = $mobileScrollTarget
        } else if ($desktopScrollTarget.length != 0) {
          $scrollTarget = $desktopScrollTarget
        }
  
        scrollTop = Math.max($scrollTarget.offset().top - (this.barHeight * 4), this.barHeight * 2)
  
        // Check if it will not stay at the middle of the screen because of maximum scroll
        const scrollMaxY = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight)
        if (scrollTop > scrollMaxY && (scrollTop - scrollMaxY) <= height / 2) {
          $('body').removeClass('no-scroll')
          $scrollTarget.get(0).scrollIntoView(false)
          $('body').addClass('no-scroll')
        } else {
          $('body').removeClass('no-scroll')
          $('html').animate({ scrollTop: scrollTop })
          $('body').addClass('no-scroll')
        }
      }

      if ($scrollTarget.length === 0) {
        if (tourStop.scrollEvent) {
          this.canContinue = false
          $(document).on(tourStop.scrollEvent, () => scrollToTarget(tourStop.scrollTarget))
        }
        return
      }

      scrollToTarget(tourStop.scrollTarget)
    }
  }

  click(tourStop){
    if(tourStop) {
      if(tourStop.clickTarget == "select_day"){
        const attr = $(".pika-table button").parent().not('.is-disabled').get(0).firstChild
        const year = attr.getAttribute("data-pika-year")
        const month = attr.getAttribute("data-pika-month")
        const day = attr.getAttribute("data-pika-day")
        $('[data-controller="components--events--calendar"]').get(0).pikaday.picker.setDate(new Date(year, month, day))
      }
      else if(tourStop.clickTarget == "select_all_sessions") {
        $("#all_visible_sessions").click()
      }
      else if (tourStop.clickTarget) {
        $(`[click-target="${tourStop.clickTarget}"]`).click()
      }

    }
  }
}
