$(document).on('turbolinks:load', () => {
  if ($('#gdpr').length !== 0) {
    $('[select2-target="entity-id"]').select2({ width: '100%' })
    
    $('#event_ids').on('select2:open', () => {
      const entityId = document.getElementById('entity_id')?.value
      if (entityId) {
        document.querySelector("#event_ids").dataset.ajaxData = JSON.stringify({
          entity_id: entityId,
        })
      }
    })

    $('#event_ids').on('select2:select', function (e) {
      const options = $('#event_ids').select2('data')
      const optionsLength = options.length 
      const selectedOptionId = e.params.data.id

      if (selectedOptionId === 0 && optionsLength > 1) {
        $('#event_ids').val([selectedOptionId]).trigger('change')
      } else if (selectedOptionId !== 0) {
        if (options.find((element) => element.id === '0')) {
          let newOptions = options.filter((el) => el.id !== '0').map((el) => el.id )
          $('#event_ids').val(newOptions).trigger('change')
        }
      }
      $('#selected_event_ids').val($('#event_ids').select2('data').map((el) => el.id ))
    })

    $('[selector="gdpr-report-submit"]').on('click', e => {
      e.preventDefault()
      e.stopPropagation()
      $('#gdpr_form').submit()
    })
  }
})
