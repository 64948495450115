export default chartId => {
  const chart = $(`#${chartId}`).highcharts()
  const $legendRows = $(`#${chartId}_legend`).find('.legend')

  $legendRows.each((index, rowEl) => {
    $(rowEl).on('mouseover', () => {
      if (chart.series[index]) {
        chart.tooltip.refresh(chart.series[index].points[0])
      } else {
        chart.tooltip.refresh(chart.series[0].points[index])
      }
    })

    $(rowEl).on('mouseout', () => chart.tooltip.hide(rowEl))
  })
}
