import EventFilters from '../partials/event_filters'
import EventSessions from '../partials/event_sessions'
import SalesByPosChart from '../partials/sales_by_pos_chart'
import { setupSalesByLocalSwitch, setupBreadcrumbsSticky } from '../../../services'
import NonBlockedOccupationLogic from '../partials/non_blocked_occupation_logic'

$(document).on('turbolinks:load', () => {
  if ($('#events-show').length !== 0) {
    new EventFilters().setup()
    new EventSessions().setup()
    setupSalesByLocalSwitch()
    new NonBlockedOccupationLogic().setup()

    $(document).on('sales_by_pos_chart:load', () => {
      new SalesByPosChart().setup()
    })

    // Setup sales only locations switch
    const salesOnlyLocationsSwitch = document.querySelector('[selector="sales_only_locations_selector"]')
    if (salesOnlyLocationsSwitch) {
      // Check if there are any location with sales to hide the text
      const locations = document.querySelectorAll('[data-location-with-sales]')
      const hasSales = Array.from(locations)
        .find(location => location.getAttribute('data-location-with-sales') === 'true')
      if (!hasSales) {
        document.querySelector('[location-selector="no-data"]').style.display = 'none'
      }

      salesOnlyLocationsSwitch.addEventListener('change', () => {
        const isSalesOnly = salesOnlyLocationsSwitch.checked

        /*
          HasSales && SalesOnly  -> Shown
          HasSales && !SalesOnly -> Shown
          !HasSales && SalesOnly  -> Hidden
          !HasSales && !SalesOnly -> Shown
        */
        const locations = document.querySelectorAll('[data-location-with-sales]')
        let selectedNumber = 0
        for (const location of locations) {
          const hasSales = location.getAttribute('data-location-with-sales') === 'true'
          if (!hasSales && isSalesOnly) {
            location.style.display = 'none'
          } else {
            location.style.display = ''
            selectedNumber++
          }
        }
        $('#selected-count').text(selectedNumber)

        if (!hasSales) {
          document.querySelector('[location-selector="no-data"]').style.display = isSalesOnly ? '' : 'none'
        }
      })
    }
    // Animate scroll to the left
    // Get the event card with the location-scroll equals true and move to its position minus 20 px (allow to show the entire card)
    const locationToScroll = document.querySelector("[location-scroll='true']")
    if (locationToScroll) $('.event--scroll').animate({ scrollLeft: locationToScroll.offsetLeft - 20 }, 500)

    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
