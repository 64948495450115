class TooltipManager {
  centerPoint = null
  moveFunction = null
  followMouse = true

  constructor (rootEl) {
    this.rootEl = rootEl
    this.tooltipEl = null
  }

  show (centerPoint, headerContent, bodyContent, followMouse = true) {
    this.followMouse = followMouse
    this.centerPoint = centerPoint
    this.tooltipEl = document.createElement('div')
    this.tooltipEl.style.position = 'absolute'
    this.tooltipEl.style.transform = 'translate(-50%, -50%)'
    this.tooltipEl.classList.add('portugal-maps-tooltip')

    // Header
    if (headerContent) {
      const tootipHeader = document.createElement('div')
      tootipHeader.classList.add('portugal-maps-tooltip-header')
      tootipHeader.innerHTML = headerContent

      this.tooltipEl.append(tootipHeader)
    }

    // Body
    if (bodyContent) {
      const tooltipBody = document.createElement('div')
      tooltipBody.classList.add('portugal-maps-tooltip-body')
      tooltipBody.innerHTML = bodyContent

      this.tooltipEl.append(tooltipBody)
    }

    // Center tooltip
    this.tooltipEl.style.left = `${window.scrollX + centerPoint.getBoundingClientRect().x + centerPoint.getBoundingClientRect().width / 2}px`
    this.tooltipEl.style.top = `${window.scrollY + centerPoint.getBoundingClientRect().y + centerPoint.getBoundingClientRect().height / 2 - 40}px`

    // Ignore hover to avoid flickering
    this.tooltipEl.onmouseover = (e) => { e.preventDefault(); e.stopPropagation() }
    
    // Set the tooltip to follow the mouse
    if (followMouse) this.moveFunction = this.move.bind(this)
    this.rootEl.addEventListener('mousemove', this.moveFunction)

    this.rootEl.append(this.tooltipEl)
  }

  move (e) {
    const mousePosition = {
      x: e.clientX,
      y: e.clientY,
      width: 0,
      height: 0
    }

    if (e.target == this.centerPoint) {
      this.tooltipEl.style.left = `${window.scrollX + mousePosition.x}px`
      this.tooltipEl.style.top = `${window.scrollY + mousePosition.y - 20}px` // TODO: Set offset
    // Only hide if it is not from a legend
    } else if (this.followMouse) {
      this.hide()
    }
  }

  hide () {
    if (this.tooltipEl) this.tooltipEl.remove()
    this.tooltipEl = null
    this.rootEl.removeEventListener('mousemove', this.moveFunction)
  }
}

export default TooltipManager
