/**
 * This service setups the columns to have the correct offset in head and body for sticky columns so that it sticks
 * correctly.
 * To use in all tables: $(document).on('render_async_load', setupTableStickyHeaders)
 */
export default () => {
  // Set offset for columns in headers
  const headerDataColumns = $('.table_container .head .z_index--header')
  $(headerDataColumns).each((_index, column) => {
    const columnWidth = $(column).width()
    const sibling = $(column).siblings('.sticky')
    sibling.css('left', columnWidth + 20)
  })

  // Set offset for columns in body
  const dataColumns = $('.table_container tbody .z_index--header')
  $(dataColumns).each((_, column) => {
    const currentRow = $(column).parent()
    const columnId = parseInt(($(column).data('column') + '').replace('column', ''))

    if (columnId === 1) return // Skip if first column

    let columnWidth = 0;

    // Add all column widths before
    [...Array(columnId - 1).keys()].forEach(index => {
      const currentColumn = currentRow.find(`[data-column='column${index + 1}']`)

      if (currentColumn.length !== 0) columnWidth += currentColumn.width()
      else {
        const rowSiblings = currentRow.siblings().filter(function(){
          return $(this).find('td[data-column="column1"]').length !== 0
        })
        columnWidth += rowSiblings.find('td[data-column="column1"]').width()
      }
    })

    $(column).css('left', columnWidth + 20)
  })
}
