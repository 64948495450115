/* global $ */
import { Controller } from '@hotwired/stimulus'
import * as Routes from 'routes.js.erb'

export default class extends Controller {
  connect () {}
  
  fetchNewData (event) {
    const BUTTON_IDS = [
      'tickets-chart-btn_0',
      'tickets-chart-btn_1',
      'tickets-chart-btn_2',
      'tickets-chart-btn_3',
      'tickets-chart-btn_4'
    ]
    // just for context
    // const GROUP_BY = {
    //   FIVE_MIN: 0,
    //   TEN_MIN: 1,
    //   FIFTEEN_MIN: 2,
    //   THIRTY_MIN: 3,
    //   HOUR: 4
    // }

    const buttonId = event.target.id
    const eventId = this.element.dataset.eventId
    const locationId = this.element.dataset.locationId

    const sessionDate = document.getElementById('session_date').value
    const sessionTime = document.getElementById('session_time').value
    const isAllSessionsTime = document.getElementById('is_all_sessions_time').value === 'true'
    const isAllSessions = document.getElementById('is_all_sessions_dates').value === 'true'

    $.ajax({
      type: 'POST',
      url: Routes.event_metrics_chart_async_path(eventId),
      data: {
        content: 'timeline_chart',
        time_change: true,
        event_id: eventId,
        location_id: locationId,
        group_by: BUTTON_IDS.indexOf(buttonId),
        selected_session_date: sessionDate,
        is_all_sessions_dates: isAllSessions,
        selected_session_time: sessionTime,
        is_all_sessions_time: isAllSessionsTime
      }
    })
  }
}
