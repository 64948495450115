$(document).on('turbolinks:load', () => {
  if ($('#first-password-change-form').length !== 0) {
    const passwordInputs = ["#new_password", "#confirm_password"]

    passwordInputs.forEach(identifier => {
      const $passwordInput = $(identifier).first()
      const $passwordVisibilityIcon = $passwordInput.parent().find('.sessions__field--icon').first()
  
      $passwordVisibilityIcon.get(0).onclick = () => {
        if ($passwordInput.attr('type') === 'password') {
          // Password visible
          $passwordInput.attr('type', 'text')
          $passwordVisibilityIcon.removeClass('fa-eye')
          $passwordVisibilityIcon.addClass('fa-eye-slash')
        } else {
          // Password hidden
          $passwordInput.attr('type', 'password')
          $passwordVisibilityIcon.removeClass('fa-eye-slash')
          $passwordVisibilityIcon.addClass('fa-eye')
        }
      }
    })
  }
})
