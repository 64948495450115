import { Controller } from '@hotwired/stimulus'
import {eventsMultiSelectUpdater} from "../../eventsMultiSelectUpdater";

// This controller allows for a dynamic search, where whenever the form target is submitted, the wrapper target
// is replaced with the response gotten from the request (corresponding to the form submitting).
// The content target is used so that we can hide the content when we are searching, and the same for the spinner target
// which is used for showing a loading spinner when searching
// The label targets are updated to allow clearing the corresponding select and updating the data
// The paginator target is updated to update the content when each page is clicked
// The eventName property is used for trigger an Event with that name - optional
export default class extends Controller {
  static targets = ["form", "wrapper", "content", "spinner", "label", "filter", "apply", "clear", "paginator"]

  connect () {
    // Prevent submiting the form and not using our action!
    this.element.controller = this
    $(this.formTarget).on('submit', (event) => this.submit(event))
  }

  // Configure filters
  filterTargetConnected (element) {
    if (this.updateOnFilter) {
      const changeEvent = element.getAttribute('data-change-event') || 'change'
      const formTarget = this.formTarget
      $(element).on(changeEvent, (event, details) => {
        event.stopPropagation()
        event.preventDefault()
        eventsMultiSelectUpdater(element)
        // Only update if this field does not exist or it is false
        // - If it is true, it does not update
        if (!details?.stop_update) {
          $(formTarget).submit()
        }
      })
    }
  }

  // Configure clear filter button
  clearTargetConnected (element) {
    if (this.updateOnFilter) {
      $(element).on('click', () => {
        for (let filterTarget of this.filterTargets) {
          // Clearable by default
          if (filterTarget.getAttribute('input_clearable') !== 'false') {
            this.clearSelect(filterTarget.getAttribute('name'))
          }
        }
        $(this.formTarget).submit()
      })
    }
  }

  // Configure label filters
  labelTargetConnected (element) {
    const selectId = element.getAttribute('data-select')
    const valueId = element.getAttribute('data-id')
    // Only if there is a selectId
    if (selectId) {
      $(element).on('click', () => {
        // Clear select associated
        this.clearSelect(selectId, valueId)
        // No need to remove the filter from list since it will refresh
        // Refresh the form
        $(this.formTarget).submit()
      })
    }
  }

  // Configure paginator
  paginatorTargetConnected (element) {
    $(element).find('a').map((_index, el) => {
      const currElement = $(el)
      // After clicking the paginator, show spinner
      currElement.on('click', () => this.showSpinner())

      // On data obtained, replace content!
      currElement.on('ajax:success', (event) => {
        const data = event.detail[2].response
        this.replaceContent(data)
      })
    })
  }

  submit (event) {
    // Stop normal submit event
    event.stopPropagation()
    event.preventDefault()

    // Prepare URL
    const query = $(event.target).serialize()
    const baseUrl = event.target.action
    const url = `${baseUrl}?${query}`

    // Hide content and show spinner
    this.showSpinner()

    // Fetch new data and replace
    fetch(url).then((response) => response.text()).then(data => this.replaceContent(data))
  }

  // CUSTOM METHODS
  showSpinner () {
    // Set spinner and hide wrapper
    $(this.spinnerTarget)?.css('display', 'block')
    $(this.contentTarget)?.css('display', 'none')
    if (this.hasApplyTarget) this.applyTarget?.setAttribute('disabled', true)
    if (this.hasClearTarget) this.clearTarget?.setAttribute('disabled', true)

    for (const filterTarget of this.filterTargets) {
      filterTarget.setAttribute('disabled', true)
    }
  }

  replaceContent (data) {
    const tempElement = document.createElement('temp')
    tempElement.innerHTML = data
    // Replace wrapper with the response html
    // The outerHTML is not available in all browsers, but it's at 97,24% (https://caniuse.com/mdn-api_element_outerhtml)
    // If necessary the alternative is: https://stackoverflow.com/a/13433551/13860978
    const innerHTML = tempElement.querySelector('[data-components--search-target="wrapper"]')?.innerHTML

    if (innerHTML) {
      this.wrapperTarget.innerHTML = innerHTML
    }

    // Redo script tags to run JS code
    // Reference: https://stackoverflow.com/questions/2592092/executing-script-elements-inserted-with-innerhtml/47614491#47614491
    Array.from(this.wrapperTarget.querySelectorAll("script")).forEach( oldScriptEl => {
      // Create new script tag with the properties of the old one
      const newScriptEl = document.createElement("script")
      Array.from(oldScriptEl.attributes).forEach(attr => newScriptEl.setAttribute(attr.name, attr.value))
      const scriptText = document.createTextNode(oldScriptEl.innerHTML)
      newScriptEl.appendChild(scriptText)

      // Replace old script tag with new one
      oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl)
    })

    // Dispatch event
    if (this.eventName) document.dispatchEvent(new Event(this.eventName))
    if (this.hasApplyTarget) this.applyTarget?.removeAttribute('disabled')
    if (this.hasClearTarget) this.clearTarget?.removeAttribute('disabled')
    for (const filterTarget of this.filterTargets) {
      filterTarget.removeAttribute('disabled')
    }
  }
  
  clearSelect(selectorName, id = null) {
    const correspondingSelector = $(`select[name='${selectorName}']`)
    if (correspondingSelector.length !== 0) {
      if(!id) {
        $(correspondingSelector).val('').trigger('change', [{stop_update: true}]) // Because select2 needs to be triggered...
      } else {
        let selValues = $(correspondingSelector).val()
        selValues = selValues.filter((selId) => id !== selId)
        $(correspondingSelector).val(selValues).trigger('change', [{stop_update: true}])
      }
    // Clear inputs like date range selector
    } else {
      const nodeSelector = $(`input[name='${selectorName}']`)
      if (nodeSelector && nodeSelector.get(0) && nodeSelector.attr('input_clearable') !== "true") return
      if (nodeSelector && nodeSelector.get(0) && nodeSelector.get(0).controller) nodeSelector.get(0).controller.clear()
      else { $(`[name="${selectorName}"]`).get(0)?.controller?.clear() }
    }
  }

  updatePaginatorURL(param, value) {
    const links = this.paginatorTarget.querySelectorAll('a')
    for (const paginator of links) {
      const url = new URL(paginator.href)
      url.searchParams.set(param, value)
      paginator.href = url.toString()
    }
  }

  get eventName () {
    return this.element.dataset.eventName
  }

  get updateOnFilter () {
    return this.element.dataset.updateOnFilter == "true"
  }
 }