const setupPasswordVisibility = (iconEl, passwordEl) => {
  iconEl.addEventListener('click', () => {
    if (passwordEl.getAttribute('type') === 'password') {
      // Password visible
      passwordEl.setAttribute('type', 'text')
      iconEl.classList.remove('fa-eye')
      iconEl.classList.add('fa-eye-slash')
    } else {
      // Password hidden
      passwordEl.setAttribute('type', 'password')
      iconEl.classList.remove('fa-eye-slash')
      iconEl.classList.add('fa-eye')
    }
  })
}

export default setupPasswordVisibility
