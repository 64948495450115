export default () => {
  // Set sales by local chart properties
  $(document).on('sales_by_local_portugal_loaded', _ => {
    const $switchPortugal = $("[selector='sales_by_local_checkbox_portugal']")
    $switchPortugal.on('change', _ => {
      // Display loading spinner before the chart loads
      $('#sales_by_local_abroad_chart_container > #sales_by_local_container_').css('display', 'block')

      // Send event to start rendering (only the first time)
      // This is needed to avoid triggering other render_asyncs, as it should have it own event
      // It also needs bubbles to trigger render_async
      if ($('#sales_by_local_container_abroad').length === 0) {
        $('#sales_by_local_portugal_chart_container').get(0).dispatchEvent(new Event('async-sales-by-local', { bubbles: true }))
      }

      $('#sales_by_local_portugal_chart_container').css('display', 'none')
      $('#sales_by_local_abroad_chart_container').css('display', 'block')
      $switchPortugal.prop('checked', false)
    })
  })

  $(document).on('sales_by_local_abroad_loaded', _ => {
    const $switchAbroad = $("[selector='sales_by_local_checkbox_abroad']")
    $switchAbroad.on('change', _ => {
      $('#sales_by_local_portugal_chart_container').css('display', 'block')
      $('#sales_by_local_abroad_chart_container').css('display', 'none')
      $switchAbroad.prop('checked', true)
    })
  })
}
