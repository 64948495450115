import Base from '../../base'
import i18n from '../../../initializers/i18n'

class EventSessions extends Base {
  setup () {
    const locale = $('body').attr('data-components--body-locale')
    i18n.locale = locale
    const applyTranslation = i18n.t('apply')
    const applyCapitalized = applyTranslation.charAt(0).toUpperCase() + applyTranslation.slice(1)

    const selectedEventSessionIds = $('#sessions_box').data('event_session_ids') || []

    document.addEventListener('sessions-loaded', function (ev) {
      if (selectedEventSessionIds.length !== 0) {
        const date = $(ev.container).get(0).id
        let filteredSessions = selectedEventSessionIds
        if (date !== 'no_calendar') {
          filteredSessions = selectedEventSessionIds.filter((event) => {
            return event.split('_')[0] === date
          })
        }

        if (filteredSessions.length !== 0) {
          // Check checkboxes from the selected sessions
          filteredSessions.forEach(function (eventSessionId) {
            $('#event_session_' + eventSessionId).prop('checked', true)
          })
        }

        // check if for this day are all selected and select select all
        let allSelected = true
        $('.organizer_session__wrapper').each(function () {
          if (this.style.display === 'block') {
            const items = this.getElementsByClassName('session_checkbox')
            for (const item of items) {
              if (!item.checked) {
                allSelected = false
              }
            }
          }
        })
        $('#all_visible_sessions').get(0).checked = allSelected
        $('#apply').get(0).value = `${applyCapitalized} (${selectedEventSessionIds.length})`
        if (selectedEventSessionIds.length !== 0) {
          $('#apply').get(0).disabled = false
        }
      }
      // enable apply button when some checkbox is selected
      $(':checkbox').each(function () {
        $(this).on('change', function () {
          let totalSelected = 0
          $('.session_checkbox').each(function () {
            if (this.checked === true) {
              totalSelected += 1
            }
          })
          if (totalSelected !== 0) {
            $('#apply').get(0).disabled = false
          } else {
            $('#apply').get(0).disabled = true
          }

          $('#apply').get(0).value = `${applyCapitalized} (${totalSelected})`
        })
      })
      registerClickForAllSessionsCheckbox()
    })

    document.addEventListener('new-month-loaded', function () {
      registerClickForAllSessionsCheckbox()
    })

    // Setup toggler and sessions box (new sessions)
    $('#toggler').on('click', function () {
      if ($('#show_more').hasClass('fa-plus')) {
        $('#partial').css('display', 'block')
        $('#show_more').addClass('fa-minus')
        $('#show_more').removeClass('fa-plus')
      } else {
        $('#partial').css('display', 'none')
        $('#show_more').addClass('fa-plus')
        $('#show_more').removeClass('fa-minus')
      }
    })
    // Open "mostrar +" when there are selected sessions
    // if ($('#sessions_box').data('event_session_ids')) {
    //   if ($('#sessions_box').data('event_session_ids').length >= 1) {
    //     $('#toggler').trigger('click')
    //   }
    // }
    // --

    $('#all_sessions').on('click', selectAllSessions)
    $('#clear_sessions').on('click', clearAllSessions)

    /* HELPERS */
    function selectAllSessions () {
      $('.tl_control_checkbox > input').each(function () { $(this).prop('checked', true) })
      $('#all_sessions_helper').val('true')
      $('#apply').get(0).disabled = false
      $('#apply').get(0).value = `${applyCapitalized} (${$('#total_sessions_count').val()})`
      $('.pika-table td').each(function () {
        if (!$(this).hasClass('is-disabled')) {
          $(this).addClass('has-event')
        }
      })
    }

    function clearAllSessions () {
      $('.tl_control_checkbox  > input').each(function () {
        $(this).prop('checked', false)
      })
      $('.pika-table td').each(function () {
        if (!$(this).hasClass('is-disabled')) {
          $(this).removeClass('has-event')
        }
      })
      $('#apply').get(0).disabled = true
      $('#apply').get(0).value = applyCapitalized
    }

    function registerClickForAllSessionsCheckbox () {
      // select all checkbox onclick behaviour
      $('.all_visible_sessions').each(function () {
        $(this).on('click', function () {
          if ($(this).is(':checked')) {
            selectAllVisibleSessions()
          } else {
            clearAllVisibleSessions()
          }
        })
      })
    }

    function selectAllVisibleSessions (ev) {
      $('.organizer_session__wrapper').each(function () {
        if (this.style.display === 'block') {
          const items = this.getElementsByClassName('session_checkbox')
          for (const item of items) {
            item.checked = true
          }
        }
      })
    }

    function clearAllVisibleSessions () {
      $('.organizer_session__wrapper').each(function () {
        if (this.style.display === 'block') {
          const items = this.getElementsByClassName('session_checkbox')
          for (const item of items) {
            item.checked = false
          }
        }
      })
    }
  }
}

export default EventSessions
