/* APPLICATION INSIGHTS */
import './application_insights'

/* JQUERY */
import './jquery'

/* CHARTKICK */
import './chartkick'

/* SELECT2 */
import 'select2'
import 'select2/dist/css/select2.css'

/* TABLE HIGHLIGHT */
import 'table_highlight.css'

/* STIMULUS */
import './stimulus'

/* BOOTSTRAP */
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

/* DATETIMEPICKER */
import 'bootstrap3-datetimepicker-rails-4.17.47/vendor/assets/javascripts/bootstrap-datetimepicker.min'
import 'bootstrap3-datetimepicker-rails-4.17.47/vendor/assets/stylesheets/bootstrap-datetimepicker.min.css'

/* DATERANGEPICKER */
import 'bootstrap-daterangepicker-rails-3.0.4/vendor/assets/javascripts/daterangepicker.js'
import 'bootstrap-daterangepicker-rails-3.0.4/vendor/assets/stylesheets/daterangepicker.css'

/* PIKADAY */
import './pikaday'
import 'pikaday/css/pikaday.css'

/* FONTAWESOME */
import '@fortawesome/fontawesome-pro/css/all.css'

import './select2_clear_cache'
