import { setupChartLegendHover, setupEntityFilter, setupSalesByLocalSwitch, setupSalesEvolutionSwitch } from '../../../services'
import Rails from '@rails/ujs'
import NonBlockedOccupationLogic from '../../events/partials/non_blocked_occupation_logic'
import i18n from "../../../initializers/i18n";

$(document).on('turbolinks:load', () => {
  if ($('#general-dashboard').length !== 0) {
    setupEntityFilter()
    setupSalesByLocalSwitch()

    const $periodForm = $('#period_filter')
    const fullSpinner = $('.full-spinner')

    // Set sales evolution chart properties
    setupSalesEvolutionSwitch()
    // Set sales by local chart properties
    setupSalesByLocalSwitch()

    // Set sales by pos chart properties
    $(document).on('sales_by_pos_loaded', _ => {
      setupChartLegendHover('sales_by_pos_chart')
    })

    // Set sales by event chart properties
    $(document).on('sales_by_event_loaded', _ => {
      setupChartLegendHover('sales_by_event_chart')

      // If the url has the tour anchor, scroll there
      const scrollId = '#total-occupation-scroll'
      if (location.hash.includes(scrollId)) {
        const updateScroll = () => {
          $('html, body').animate({
            scrollTop: $(scrollId).offset().top - 80 // Remove nav height
          }, 200)
        }
        // create an Observer instance
        const resizeObserver = new ResizeObserver(() =>
          // console.log('Body height changed:', entries[0].target.clientHeight)
          updateScroll()
        )

        // start observing a DOM node
        resizeObserver.observe(document.body)
      }
    })

    // Period filter set loading spinner
    $('#period_filter .select').on('change', e => {
      const selectedPeriod = $(e.target).find(':selected').val()
      const customFilterValue = $(e.target).attr('data-custom-filter')

      if (selectedPeriod === customFilterValue) {
        $('.dashboard_date_filter').css('display', 'block')
      } else {
        fullSpinner.css('display', 'block')
        $periodForm.submit()
      }
    })

    $('.dashboard_date_filter__submit input').click((e) => {
      fullSpinner.css('display', 'block')
      $periodForm.submit()
    })

    // Verify correctness on date updates
    $('#filter_start_date').on('dp.change', function (e) {
      $('#filter_end_date').data('DateTimePicker').minDate(e.date)
    })

    $('#filter_end_date').on('dp.change', function (e) {
      $('#filter_start_date').data('DateTimePicker').maxDate(e.date)
    })

    // On occupation list load
    $(document).on('total_occupation_list_loaded', _ => {
      // Setup selectors
      const venueSelect = $('[select2-target="venue-filter"]')
      venueSelect.select2({ width: 100 })
      // Force to set the Include blank option
      if (venueSelect.length && !venueSelect.val().length){
        venueSelect.val(null).trigger('change')
      }
      // Store previous selected values
      venueSelect.data('data-prev', venueSelect.val())

      new NonBlockedOccupationLogic().setup()

      const ulDiv = $(venueSelect).siblings('span.select2').find('ul')
      const count = $(venueSelect).select2('data').length
      const firstId = $(venueSelect).select2('data')[0].id ?? null;
      if(firstId === '') return;
      if (count > 1) {
        const local = "views.general.dashboard.selects.locals"
        const selected = "views.general.dashboard.selects.selected_plural"
        ulDiv.html("<li class='select2-selection__choice select2-selection__counter'>"
            + count + " " + i18n.t(local) + " " + i18n.t(selected) + "</li>")
      }

      // Venue filter set loading spinner
      venueSelect.on('change', () => {
        const options = Array.from(venueSelect.get(0).options)
        const previousOption = venueSelect.data('data-prev')
        let selectedOptions = Array.from(venueSelect.get(0).selectedOptions)?.map(option => option.value)

        const allOptionsSelected = selectedOptions.length === (options.length - 1);

        // Prevents to do this logic in case we remove a selected element
        if (!allOptionsSelected && previousOption.length <= selectedOptions.length) {
          const newOption = selectedOptions.filter(value => !previousOption.includes(value))[0];
          const venueRoom = newOption?.split('_');

          if (venueRoom[0] === '') {
            selectedOptions = [''];
          } else if (venueRoom[0] !== '' && venueRoom[1] === '') {
            options.forEach((element) => {
              if (element.value.includes(venueRoom[0]) && !selectedOptions.includes(element.value)) {
                selectedOptions.push(element.value);
              }
            });
          }
        }

        if (selectedOptions.includes('') && selectedOptions.length > 1){
          selectedOptions = selectedOptions.filter((el) => el !== '')
        }
        if (allOptionsSelected) {
          selectedOptions = ['']
        }

        venueSelect.val(selectedOptions).trigger('change.select2')
      })
    })
  }
})
