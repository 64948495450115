import EventSessions from '../partials/event_sessions'
import { setupSalesByLocalSwitch, setupBreadcrumbsSticky } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#events-sales-by-local').length !== 0) {
    new EventSessions().setup()
    setupSalesByLocalSwitch()
    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
