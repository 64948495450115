export default function setupTogglers() {
  $('.toggler').off('click').on('click', function(ev) {
    ev.stopPropagation()

    const eventId = $(this).attr('event_id')
    const parent = $(this).parent()
    const $eventRow = $('.event_' + eventId)
    $('#arrow_' + eventId).toggleClass('down')

    if ($eventRow.hasClass('dropdown__slide')) {
      if ($eventRow.hasClass('dropdown__slide--open')) {
        $eventRow.removeClass('dropdown__slide--open')
        parent.removeClass('active')
        $eventRow.addClass('dropdown__slide--close dropdown__slide--no-border')
      }
      else if ($eventRow.hasClass('dropdown__slide--close')) {
        $eventRow.removeClass('dropdown__slide--close')
        $eventRow.removeClass('dropdown__slide--no-border')
        $eventRow.addClass('dropdown__slide--open')
        parent.addClass('active')
      }
    }
  })
}