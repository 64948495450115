export default function setupSelect (element, currDocument = document, onchange = (value) => { }) {
  const $this = $(element)

  if ($this.parent().hasClass('select_container')) return

  // Cache the number of options
  const numberOfOptions = $this.children('option').length

  // Hides the select element
  $this.addClass('s-hidden')

  // Wrap the select element in a div and cache the div
  const $selectContainer = $this.wrap('<div class="select_container"></div>').parent()

  // Insert a styled div to sit over the top of the hidden select element
  $this.after('<div class="styled_select"></div>')

  // Cache the styled div
  const $styledSelect = $this.next('div.styled_select')

  // Show the first select option in the styled div
  // $styledSelect.text($this.children('option:selected').text())
  $styledSelect.append("<span class='styled_select__text-wrapper'>" + $this.children('option:selected').text() + '</span>')

  // Insert an unordered list after the styled div and also cache the list
  let $list = null

  if (
    $this.parent().parent().hasClass('select--manager') ||
    $this.parent().parent().parent().hasClass('select--manager')
  ) {
    $list = $('<ul />', { class: 'options options--manager styled-select-options' }).insertAfter(
      $styledSelect
    )
  } else {
    $list = $('<ul />', { class: 'options styled-select-options' }).insertAfter($styledSelect)
  }

  // Insert a list item into the unordered list for each select option
  for (let i = 0; i < numberOfOptions; i++) {
    const $option = $this.children('option').eq(i);
    const $listItem = $('<li />', {
      text: $option.text(),
      rel: $option.val()
    }).appendTo($list);

    if ($option.attr('disabled')) {
      $listItem.addClass('disabled')
    }
  }

  // Cache the list items
  const $listItems = $list.children('li')

  // handle span click
  $selectContainer.click(function (e) {
    e.stopPropagation()
    for (const s of currDocument.querySelectorAll('div.styled_select.active')) {
      if (s !== $styledSelect[0]) {
        $(s).removeClass('active').next('ul.options').hide()
      }
    }
    if ($styledSelect.hasClass('active')) {
      $($styledSelect).removeClass('active').next('ul.options').hide()
    } else {
      $($styledSelect).addClass('active').next('ul.options').show()
    }
  })

  // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
  // Updates the select element to have the value of the equivalent option
  $listItems.click(function (e) {
    e.stopPropagation()
    // $styledSelect.text($(e.target).text()).removeClass('active')
    $styledSelect.find('span').remove()
    $styledSelect.append("<span class='styled_select__text-wrapper'>" + $(e.target).text() + '</span>').removeClass('active')
    $this.val($(e.target).attr('rel')).trigger('change')
    onchange($(e.target).attr('rel'))
    $list.hide()
  })

  // Check if text is too big
  const maxLength = 14
  if ($styledSelect.prevObject[0].className.includes('stripped-text')) {
    $styledSelect.text(function (i, text) {
      if (text.length > maxLength) {
        return text.substr(0, maxLength) + '...'
      }
    })
  }

  // Hides the unordered list when clicking outside of it
  $(document, currDocument).click(function () {
    $styledSelect.removeClass('active')
    $list.hide()
  })
}
