import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["collapse", "spinner"]

  download () {
    const name = this.element.dataset.name

    Cookies.set(name, false)
    let $spinner = null
    if (this.hasSpinnerTarget) {
      $spinner = $(this.spinnerTarget)
    } else {
      $spinner = $('.full-spinner')
    }
    const $downloads = $(this.collapseTarget)
    $downloads.css('display', 'none')
    $spinner.fadeIn()

    const interval = setInterval(function () {
      const download_complete = Cookies.get(name)
      if (download_complete == 'true') {
        $spinner.css('display', 'none')
        $downloads.fadeIn()
        clearInterval(interval)
      }
    }, 1000)

  }
}

