import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    $(this.element).datetimepicker({
      format: this.format,
      defaultDate: this.defaultDate,
      minDate: this.minDate,
      maxDate: this.maxDate,
      sideBySide: true,
      locale: 'pt',
      icons: {
        next: 'fa fa-angle-right',
        previous: 'fa fa-angle-left'
      }
    })
  }

  get format () {
    return this.data.get('format') || 'DD/MM/YYYY'
  }

  get defaultDate () {
    const defaultDate = this.data.get('defaultDate')
    return defaultDate ? new Date(defaultDate) : $.fn.datetimepicker.defaults.defaultDate
  }

  get minDate () {
    const minDate = this.data.get('minDate')
    return minDate ? new Date(minDate) : $.fn.datetimepicker.defaults.minDate
  }

  get maxDate () {
    const maxDate = this.data.get('maxDate')
    return maxDate ? new Date(maxDate) : $.fn.datetimepicker.defaults.maxDate
  }
}
