import EventSessions from '../partials/event_sessions'
import { setupBreadcrumbsSticky } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#events-sales-by-typology').length !== 0) {
    new EventSessions().setup()
    // Set sticky breadcrumbs
    setupBreadcrumbsSticky()

    let activeToggles = document.querySelectorAll('[is-typology-toggle="true"]')
    let currSelected = ''

    // If only one element
    if (activeToggles.length === 1) {
      currSelected = activeToggles[0].getAttribute('selector-div')
    } else if (activeToggles.length >= 1) {
      // if we have more than one element we will always want typology map not displayed
      currSelected = 'occupation-map'
      document.querySelector('[selector="typology-map"]').style.display = 'none'
    }

    // Setup toggle for occupation map and typology report
    document.querySelectorAll('[is-typology-toggle="true"]').forEach(element => {
      element.addEventListener('click', () => {
        const newSelection = element.getAttribute('selector-div')
        if (currSelected !== newSelection) {
          // Hide previous tab
          document.querySelector(`[selector="${currSelected}"]`).style.display = 'none'
          document.querySelector(`[selector-div="${currSelected}"] > p`).classList.remove('separator_active')

          // Store new tab
          currSelected = newSelection

          // Show new tab
          document.querySelector(`[selector="${currSelected}"]`).style.display = ''
          document.querySelector(`[selector-div="${currSelected}"] > p`).classList.add('separator_active')
        }
      })
    })
  }
})
