import Cookies from 'js-cookie'

const ttlCookieId = 'TL-SESSION-TTL'

export default function sessionTimeoutCheck () {
  const threshold = 30 // 30 seconds before expiring
  const ttl = Cookies.get(ttlCookieId) // in seconds

  setTimeout(renewToken, (ttl - threshold) * 1000)
}

function renewToken () {
  const request = new XMLHttpRequest()
  request.onload = (event) => {
    const status = event.target.status
    const response = event.target.response
    if (status === 200) {
      Cookies.set(ttlCookieId, response)
      sessionTimeoutCheck()
    }
  }
  request.open('POST', '/renew_token', true)
  request.responseType = 'json'
  request.send()
}

// Set for each page to check session timeout
$(document).on('turbolinks:load', () => {
  const keepSession = document.querySelector('body').getAttribute('data-keep-session') === 'true'
  // Don't try to keep session alive if on login
  if (keepSession) {
    sessionTimeoutCheck()
  }
})
