import 'moment/locale/pt.js'
import { easepick } from '@easepick/core'
import { AmpPlugin } from '@easepick/amp-plugin'
import { LockPlugin } from '@easepick/lock-plugin'
import { Controller } from '@hotwired/stimulus'
import i18n from '../../../../initializers/i18n'
import setupSelect from '../../../../services/select_service'
import { DateTime } from '@easepick/bundle'

export default class extends Controller {
  static targets = ['sessionDate', 'submit', 'cssLink']

  connect() {
    const controller = this
    this.element.controller = controller

    // Set translations
    const locale = $('body').get(0).bodyController.locale
    i18n.locale = locale

    const Core = easepick.create
    this.picker = new Core({
      element: this.sessionDateTarget,
      plugins: [AmpPlugin, LockPlugin],
      css: [],
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
          minYear: this.minYear(),
          maxYear: this.maxYear()
        },
        darkMode: false,
        resetButton: false,
      },
      LockPlugin: this.lockPluginOptions(),
      lang: locale,
      zIndex: 1000,
      calendars: 1,
      grid: 1,
      setup(picker) {
        picker.on('render', () => {
          if (!picker.ui.container.querySelector('.calendar-footer')) {
            controller.styleSelect(picker)
            controller.addFooter(picker)
            controller.fixWeekdays(picker)
          }
        })
        picker.on('show', () => {
          controller.highlightTarget("add")
        })
        picker.on('hide', () => {
          controller.highlightTarget("remove")
        })
        picker.on('select', () => {
          controller.highlightTarget("remove")
          const sessionDate = document.getElementById('session_date').value
          if (new Date(sessionDate) > 0) {
            const locationId = document.getElementById('location_id').value
            const eventId = document.getElementById('event_id').value
            
            document.getElementById('is_all_sessions_dates').value = false
            document.getElementById('is_all_sessions_time').value = false
            document.querySelector("#session_time").dataset.ajaxData = JSON.stringify({
              event_id: eventId,
              location_id: locationId,
              is_all_sessions: false,
              selected_session: sessionDate
            })

            // Clear value from select2
            $('#session_time').val(null).trigger('change')
          }
        })
      }
    })

    // Refresh calendar
    this.sessionDateTarget.addEventListener('click', () => {
      if (controller.picker.isShown() ) {
        controller.picker.hide()
        controller.picker.show({ target: controller.sessionDateTarget })
      }
    })

    if (this.allSessions) {
      document.getElementById('session_date').value = i18n.t('all_sessions')
      this.picker.gotoDate(this.availableDates[0])
    } else {
      this.picker.setDate(this.startDate)
      this.picker.gotoDate(this.startDate)
    }

    // Apply external styles (from assets) to the shadow DOM
    const linkElem = document.createElement("div")
    // Set the attribute
    linkElem.innerHTML = this.cssLinkTarget.dataset.html
    // Attach the created element to the shadow DOM
    this.picker.ui.shadowRoot.appendChild(linkElem.firstChild)

    this.picker.ui.wrapper.style.display = '';
  }

  highlightTarget(type) {
    if (type === "add" && !this.sessionDateTarget.classList.contains('selected')) {
      this.sessionDateTarget.classList.add('selected')
    } else if (type === "remove" && this.sessionDateTarget.classList.contains('selected')){
      this.sessionDateTarget.classList.remove('selected')
    }
  }

  addFooter(picker) {
    if (!picker.ui.container.querySelector('.calendar-footer')) {
      const footer = document.createElement('footer')
      footer.classList.add('calendar-footer')

      // Add apply and cancel buttons
      const footerButtons = document.createElement('div')
      footerButtons.classList.add('buttons-wrapper');

      if (this.availableDates) {
        const allDatesButton = document.createElement('button')
        allDatesButton.id = "button_all_sessions";
        allDatesButton.classList.add('apply-button', 'unit')
        allDatesButton.classList.add('primary_manager_button')
        allDatesButton.innerHTML = i18n.t('all_dates')

        allDatesButton.addEventListener('click', () => {
          this.highlightTarget("remove")
          $("#metrics_spinner").show()

          document.getElementById('is_all_sessions_dates').value = true
          document.getElementById('is_all_sessions_time').value = true
          document.getElementById('session_date').value = i18n.t('all_sessions')

          // Submit form when seleting all dates
          $(this.submitTarget).click()

          // Disable must be after submitting 
          document.getElementById('location_id').disabled = true
          $("#session_time").prop("disabled", true)
          for (const select of document.querySelectorAll('[input_clearable="true"]')) {
            select.setAttribute('disabled', 'disabled')
            $(select).parent().find('.styled_select').attr('disabled', 'disabled')
          }

          $("#metrics_stats").hide()
          $("#metrics_cards").hide()
        })
        footerButtons.append(allDatesButton)
      }

      // Add footer
      footer.append(footerButtons)
      picker.ui.container.append(footer)
    }
  }

  styleSelect(picker) {
    const selects = picker.ui.container.querySelectorAll('select')
    for (const select of selects) {
      // Month dropdown
      if (select.classList.contains('month-name--dropdown')) {
        setupSelect(select, picker.ui.shadowRoot, (value) => {
          picker.calendars[0].setDate(1)
          picker.calendars[0].setMonth(Number(value))
          picker.renderAll()
        })
      } // Year dropdown
      else {
        setupSelect(select, picker.ui.shadowRoot, (value) => {
          picker.calendars[0].setFullYear(Number(value))
          picker.renderAll()
        })
      }
    }
  }

  fixWeekdays(picker) {
    const days = picker.ui.container.querySelectorAll('.daynames-row .dayname')
    for (let elIdx = 0; elIdx < days.length; elIdx++) {
      const el = days[elIdx]
      const dayIdx = 7 - 4 + picker.options.firstDay + (elIdx + 1)
      el.innerHTML = new Date(1970, 0, dayIdx, 12, 0, 0, 0)
        .toLocaleString(picker.options.lang, { weekday: 'narrow' });

      el.title = new Date(1970, 0, dayIdx, 12, 0, 0, 0)
        .toLocaleString(picker.options.lang, { weekday: 'long' })
    }
  }

  lockPluginOptions() {
    const dates = this.availableDates
    // Hack: to lock all dates for min date is set today and max date is set yesterday
    let minDate = new Date()
    let maxDate = new Date(Date.now() - 86400000)

    if (this.availableDates) {
      minDate = dates[0]
      maxDate = dates.slice(-1)[0]
    }

    return {
      minDate: minDate,
      maxDate: maxDate,
      filter: (d) => {
        const currDate = d.format("YYYY-MM-DD")
        if (dates) {
          return !dates.includes(currDate)
        } else {
          return false
        }
      }
    }
  }

  updatePicker() {
    const options = this.lockPluginOptions()
    this.picker.options.LockPlugin = options
    this.picker.PluginManager.instances.LockPlugin.options = options
    this.picker.PluginManager.instances.LockPlugin.onAttach()
    if (Object.keys(options)?.length > 0) this.picker.ui.container.classList.add('with-lock')
    else this.picker.ui.container.classList.remove('with-lock')
    this.picker.clear()
    this.picker.gotoDate(this.availableDates[0])
  }
  
  minYear() {
    const dates = this.availableDates
    if (dates) {
      return new Date(dates[0]).getFullYear()
    } else {
      return new Date().getFullYear()
    }
  }

  maxYear() {
    const dates = this.availableDates
    if (dates) {
      return new Date(dates.slice(-1)[0]).getFullYear()
    } else {
      return new Date().getFullYear()
    }
  }

  get startDate() {
    const startDate = this.data.get('startDate')
    return startDate ? new DateTime(new Date(startDate)) : null
  }

  get allSessions() {
    const allSessions = this.data.get('allSessions')
    return allSessions === 'true' ? true : false
  }

  get availableDates() {
    const dates = JSON.parse(document.getElementById("session_metrics_calendar").dataset.availableDates)
    return dates && dates.length > 0 ? dates : null
  }
}

