import { setupSalesByLocalSwitch, setupBreadcrumbsSticky } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#dashboard-sales-by-local').length !== 0) {
    const $form = $('#period_filter')
    const fullSpinner = $('.full-spinner')

    // Set sales by local chart properties
    setupSalesByLocalSwitch()

    // Period filter set loading spinner
    $('#period_filter .select').on('change', e => {
      fullSpinner.css('display', 'block')
      $form.submit()
    })

    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
