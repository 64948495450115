import moment from 'moment'
import 'moment/locale/pt.js'
import { easepick } from '@easepick/core'
import { RangePlugin } from '@easepick/range-plugin'
import { AmpPlugin } from '@easepick/amp-plugin'
import { LockPlugin } from '@easepick/lock-plugin'
import { Controller } from '@hotwired/stimulus'
import i18n from '../../initializers/i18n'
import setupSelect from '../../services/select_service'
import { DateTime } from '@easepick/bundle'

let id = 0

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'submit', 'cssLink']
  currStartDate = null
  currEndDate = null
  isShown = false
  isDayClick = true
  isReset = false
  saveCurrentDate = null

  // NOTE: Please refactor this controller, it has too many readability and logic issues
  connect() {
    const controller = this
    this.element.controller = controller
    this.data.set("id", id++)

    // Set translations
    const locale = $('body').get(0).bodyController.locale
    i18n.locale = locale

    // NOTE: This is not used currently, so that the reset button erases the current dates
    let resetFunction = false
    if (this.canReset) {
      resetFunction = () => {
        this.picker.setDateRange(new DateTime(this.resetStartDate), new DateTime(this.resetEndDate))
      }
    }

    const lockPlugin = {
      selectForward: false,
      ...(this.lockEndDate === "true" && { maxDate: new Date() }),
      ...(this.blockStartDate === "true" && { minDate: this.startDate })
    };

    const Core = easepick.create
    this.picker = new Core({
      element: this.startDateTarget,
      plugins: [AmpPlugin, RangePlugin, LockPlugin],
      css: [],
      AmpPlugin: {
        dropdown: {
          months: true,
          years: true,
          minYear: new Date().getFullYear() - 5,
          maxYear: new Date().getFullYear() + 5
        },
        darkMode: false,
        resetButton: false,
      },
      RangePlugin: {
        tooltip: false,
        elementEnd: this.endDateTarget,
        repick: true,
        strict: false,
      },
      LockPlugin: lockPlugin,
      lang: locale,
      autoApply: true,
      zIndex: 1000,
      calendars: 2,
      grid: 2,
      setup(picker) {
        picker.on('view', (e) => {
          const { view, target } = e.detail
          // Force in range for when resetted
          if (view === 'CalendarDay' && controller.isReset) {
            const date = new DateTime(target.dataset.time)
            const start = controller.currStartDate
            const end = controller.currEndDate

            if (start && start.isSame(date, 'day')) {
              target.classList.add('start');
            }

            if (start && end) {
              if (end.isSame(date, 'day')) {
                target.classList.add('end');
              }

              if (date.isBetween(start, end)) {
                target.classList.add('in-range');
              }
            }
          }
        })
        picker.on('render', () => {
          if (!picker.ui.container.querySelector('.calendar-footer')) {
            controller.styleSelect(picker)
            controller.addFooter(picker)
            controller.fixWeekdays(picker)
          }
        })

        picker.on('clear', () => {
          controller.saveCurrentDate = null
          controller.currStartDate = null
          controller.currEndDate = null
        })
        picker.on('show', (e) => {
          // const inputSelect = e.detail.target
          const inputSelect = controller.picker.PluginManager.instances.RangePlugin.triggerElement
          controller.highlightTarget(inputSelect)
          controller.isDayClick = false

          const applyButton = controller.picker.ui.container.querySelector('.apply-button')
          if (controller.currStartDate && controller.currEndDate) {
            picker.datePicked = [controller.currStartDate, controller.currEndDate]
            picker.datePicked.length = 2
            applyButton?.removeAttribute('disabled')
          } else {
            if (controller.startDateTarget.value && controller.endDateTarget.value) {
              controller.currStartDate = new DateTime(controller.startDateTarget.value)
              controller.currEndDate = new DateTime(controller.endDateTarget.value)
              picker.setStartDate(new DateTime(controller.currStartDate))
              picker.setEndDate(new DateTime(controller.currEndDate))
              controller.saveCurrentDate = null
            }
            applyButton?.setAttribute('disabled', '')
          }

          if (controller.saveCurrentDate ) {
            picker.gotoDate(controller.saveCurrentDate)
          }
        })
        picker.on('hide', (e) => {
          controller.highlightTarget()
          controller.isDayClick = false
        })
        picker.on('select', (e) => {
          const { start, end } = e.detail
          controller.isReset = false

          // Update displayed dates
          if (start)
            picker.ui.container.querySelector('[calendar-selector="displayed-start-date"]')
                .innerText = moment(start).format('D MMM YYYY')
          if (end)
            picker.ui.container.querySelector('[calendar-selector="displayed-end-date"]')
                .innerText = moment(end).format('D MMM YYYY')

          // If the users starts from end to start
          if ((!start && end)) {
            picker.clear()
            picker.setEndDate(new DateTime(end))
            controller.currEndDate = end
            controller.picker.PluginManager.instances.RangePlugin.triggerElement = controller.startDateTarget
            controller.picker.PluginManager.instances.RangePlugin.initializeRepick()
          }
          // If the user has dates and changes the end to before the start
          else if ((!!(start && end) && moment(end) < moment(start))) {
            if (controller.currStartDate.toString() === start.toString()) {
              picker.clear()
              picker.setStartDate(new DateTime(end))
              controller.currStartDate = end
            } else if (controller.currEndDate.toString() === end.toString()) {
              picker.clear()
              picker.setStartDate(new DateTime(start))
              controller.currStartDate = start
            }

            controller.currEndDate = null
            controller.picker.PluginManager.instances.RangePlugin.triggerElement = controller.endDateTarget
            controller.picker.PluginManager.instances.RangePlugin.initializeRepick()
          }
          // If changes the start date
          else if (!controller.currStartDate || start.toString()) {
            controller.currStartDate = start
            controller.currEndDate = end
            controller.picker.PluginManager.instances.RangePlugin.triggerElement = controller.endDateTarget
            if (end && controller.currEndDate) {
              picker.datePicked = [new DateTime(start), new DateTime(end)]
              picker.datePicked.length = 2
              controller.picker.PluginManager.instances.RangePlugin.initializeRepick()
            }
            // If changes the end date
          } else if (!controller.currEndDate || end.toString() != controller.currEndDate.toString()) {
            controller.currStartDate = start
            controller.currEndDate = end
            picker.datePicked = [new DateTime(start), new DateTime(end)]
            picker.datePicked.length = 2
            if (start) controller.picker.PluginManager.instances.RangePlugin.triggerElement = controller.endDateTarget
            else controller.picker.PluginManager.instances.RangePlugin.triggerElement = controller.startDateTarget
            controller.picker.PluginManager.instances.RangePlugin.initializeRepick()
          }
          controller.isDayClick = true

          const applyButton = controller.picker.ui.container.querySelector('.apply-button')
          if (controller.currStartDate && controller.currEndDate) applyButton?.removeAttribute('disabled')
          else applyButton?.setAttribute('disabled', '')

          if (picker.isShown()) {
            controller.highlightTarget(controller.picker.PluginManager.instances.RangePlugin.triggerElement)
          }

          // If there is no apply button, hide picker
          if (!controller.showApply && start && end) {
            controller.isDayClick = false
            picker.hide()
          }
        })
      }
    })

    // Patch show function for sticking it to the first element
    this.picker.show = function (event) {
      const controllerId = this.ui.wrapper.parentElement.controller.data.get('id')
      if (this.isShown() || controllerId != controller.data.get('id')) return

      const target = event && 'target' in event ? event.target : this.options.element;
      let { top, left } = this.adjustPosition(controller.startDateTarget);
      //this.ui.container.style.position = 'absolute';
      this.ui.container.style.top = `${top}px`;
      this.ui.container.style.left = `${left}px`;
      this.ui.container.classList.add('show');

      const rect = this.ui.container.getBoundingClientRect()
      const width = rect.width
      const height = rect.height
      const xPos = rect.x
      const yPos = rect.y

      const margin = 20
      // Too far to the right
      if ((width + xPos + margin) > window.innerWidth) {
        left = window.innerWidth - (width + xPos + margin)
        this.ui.container.style.left = `${left}px`;
      }
      // Too far down
      if ((height + yPos + margin) > window.innerHeight) {
        top -= top + height
        // After moving up, check if it's too far up
        const navbar = document.getElementsByClassName("manager-nav")[0];
        const navbarRect = navbar.getBoundingClientRect()
        const dateRangePickerEl = document.getElementById("date-range-picker")
        const pickerRect = dateRangePickerEl.getBoundingClientRect()

        // distance from navbar to the datepicker select
        let distance = Math.abs(navbarRect.bottom - pickerRect.top)

        if (distance < height) {
          top = -distance
        }
        this.ui.container.style.top = `${top}px`;
      }

      this.trigger('show', { target: target });
    }
    // Patch hide function to avoid autoApply hiding and resetting selection
    this.picker.hide = function (_event) {
      const controllerId = this.ui.wrapper.parentElement.controller.data.get('id')
      if (controller.isDayClick || controllerId != controller.data.get('id')) return

      controller.saveCurrentDate = this.calendars[0]
      this.ui.container.classList.remove('show');

      this.datePicked.length = 0;

      this.renderAll();

      this.trigger('hide');
    }

    this.picker.on('mouseleave', () => {
      controller.isDayClick = false
      if (controller.currStartDate && controller.currEndDate) {
        controller.picker.datePicked = [controller.currStartDate, controller.currEndDate]
        controller.picker.datePicked.length = 2
      }
    }, true)
    this.picker.on('mouseenter', () => {

    }, true)

    if (!this.startEmpty) {
      this.picker.setDateRange(new DateTime(this.startDate), new DateTime(this.endDate))
    }

    this.startDateTarget.addEventListener('click', () => {
      if (controller.picker.isShown() && !controller.canShow) {
        controller.isReset = true
        controller.isDayClick = false
        controller.picker.hide()
        controller.picker.show({ target: controller.startDateTarget })
      }
    })
    this.endDateTarget.addEventListener('click', () => {
      if (controller.picker.isShown()) {
        controller.isReset = true
        controller.isDayClick = false
        controller.picker.hide()
        controller.picker.show({ target: controller.endDateTarget })
      }
    })

    // Set up click outside of picker
    document.addEventListener('click', (e) => {
      let target = e.target
      let host = null

      if (target.shadowRoot) {
        target = e.composedPath()[0]
        host = e.composedPath().find((e) => e.classList?.contains('easepick-wrapper'))
      } else {
      }

      if (controller.picker.isShown()
          && host !== controller.picker.ui.wrapper
          && target !== controller.picker.options.element && target !== controller.endDateTarget) {
        this.isDayClick = false
        controller.picker.ui.container.classList.remove('show')
        controller.picker.datePicked.length = 0
        controller.picker.renderAll()
        controller.picker.trigger('hide')
      }

      controller.saveCurrentDate = null
    })

    // Apply external styles (from assets) to the shadow DOM
    const linkElem = document.createElement("div")
    // Set the attribute
    linkElem.innerHTML = this.cssLinkTarget.dataset.html
    // Attach the created element to the shadow DOM
    this.picker.ui.shadowRoot.appendChild(linkElem.firstChild)

    this.picker.ui.wrapper.style.display = '';
  }

  highlightTarget(target) {
    if (target == this.startDateTarget) {
      this.startDateTarget.classList.add('selected')
      this.endDateTarget.classList.remove('selected')
    } else if (target == this.endDateTarget) {
      this.startDateTarget.classList.remove('selected')
      this.endDateTarget.classList.add('selected')
    } else {
      this.endDateTarget.classList.remove('selected')
      this.startDateTarget.classList.remove('selected')
    }
  }

  addFooter(picker) {
    if (!picker.ui.container.querySelector('.calendar-footer')) {
      const footer = document.createElement('footer')
      footer.classList.add('calendar-footer')

      // Add date text
      const dateRangeEl = document.createElement('div')
      dateRangeEl.classList.add('date-range-container');
      dateRangeEl.setAttribute('calendar-selector', 'displayed-date-range')
      const startDateEl = document.createElement('span')

      startDateEl.setAttribute('calendar-selector', 'displayed-start-date')
      if (picker.getStartDate()) {
        startDateEl.innerText = moment(picker.getStartDate()).format('D MMM YYYY')
        startDateEl.classList.remove('label--disabled');
        startDateEl.classList.add('label');
      }
      else {
        startDateEl.classList.add('label--disabled');
        startDateEl.classList.remove('label');
        startDateEl.innerText = i18n.t('choose_start_date')
      }

      const endDateEl = document.createElement('span')
      endDateEl.setAttribute('calendar-selector', 'displayed-end-date')
      if (picker.getEndDate()) {
        endDateEl.innerText = moment(picker.getEndDate()).format('D MMM YYYY')
        endDateEl.classList.remove('label--disabled');
        endDateEl.classList.add('label');
      }
      else {
        endDateEl.classList.add('label--disabled');
        endDateEl.classList.remove('label');
        endDateEl.innerText = i18n.t('choose_end_date')
      }

      const separatorEl = document.createElement('span')
      $('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M335 408.1C330.3 404.3 328 398.2 328 392s2.344-12.28 7.031-16.97L430.1 280H24C10.75 280 0 269.2 0 255.1C0 242.7 10.75 232 24 232h406.1l-95.03-95.03c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0l136 136c9.375 9.375 9.375 24.56 0 33.94l-136 136C359.6 418.3 344.4 418.3 335 408.1z"/></svg>')
          .appendTo($(separatorEl))
      separatorEl.firstChild.classList.add("svg-container");
      dateRangeEl.append(startDateEl)
      dateRangeEl.append(separatorEl.firstChild)
      dateRangeEl.append(endDateEl)
      footer.append(dateRangeEl)

      // Add apply and cancel buttons
      const footerButtons = document.createElement('div')
      footerButtons.classList.add('buttons-wrapper');
      const resetButton = document.createElement('button')
      resetButton.classList.add('cancel-button', 'unit')
      resetButton.classList.add('light_manager_button')
      resetButton.innerHTML = i18n.t('reset_dates')
      resetButton.addEventListener('click', (e) => {
        e.stopPropagation()
        this.reset()
      })
      footerButtons.append(resetButton)

      if (this.showApply) {
        const applyButton = document.createElement('button')
        applyButton.classList.add('apply-button', 'unit')
        applyButton.classList.add('primary_manager_button')
        applyButton.innerHTML = i18n.t('apply')
        if (!this.currStartDate || !this.currEndDate) applyButton.setAttribute('disabled', '')
        applyButton.addEventListener('click', () => {
          this.highlightTarget()
          $(this.submitTarget).click()
        })
        footerButtons.append(applyButton)
      }

      // Add footer
      footer.append(footerButtons)
      picker.ui.container.append(footer)
    }
  }

  styleSelect(picker) {
    const selects = picker.ui.container.querySelectorAll('select')
    for (const select of selects) {
      // Month dropdown
      if (select.classList.contains('month-name--dropdown')) {
        setupSelect(select, picker.ui.shadowRoot, (value) => {
          picker.calendars[0].setDate(1)
          picker.calendars[0].setMonth(Number(value))
          picker.renderAll()
        })
      } // Year dropdown
      else {
        setupSelect(select, picker.ui.shadowRoot, (value) => {
          picker.calendars[0].setFullYear(Number(value))
          picker.renderAll()
        })
      }
    }
  }

  fixWeekdays(picker) {
    const days = picker.ui.container.querySelectorAll('.daynames-row .dayname')
    for (let elIdx = 0; elIdx < days.length; elIdx++) {
      const el = days[elIdx]
      const dayIdx = 7 - 4 + picker.options.firstDay + (elIdx + 1)
      el.innerHTML = new Date(1970, 0, dayIdx, 12, 0, 0, 0)
          .toLocaleString(picker.options.lang, { weekday: 'narrow' });

      el.title = new Date(1970, 0, dayIdx, 12, 0, 0, 0)
          .toLocaleString(picker.options.lang, { weekday: 'long' })
    }
  }

  clear() {
    this.picker.clear()
  }

  reset () {
    // Store new date
    this.isReset = true
    this.picker.setDateRange(this.resetStartDate, this.resetEndDate)
    this.currStartDate = this.resetStartDate
    this.currEndDate = this.resetEndDate
    this.picker.trigger('mouseleave') // To recuperate from our meddling - PLEASE DO NOT REMOVE THIS

    // Go to date to show it!
    this.picker.gotoDate(this.resetStartDate)
    this.picker.ui.container.querySelector('.apply-button').removeAttribute('disabled')
    this.picker.trigger('mouseenter', { target: this.picker.ui.container }) // To recuperate from our meddling - PLEASE DO NOT REMOVE THIS
  }

  get canReset() {
    return this.data.get('resetStartDate') && this.data.get('resetEndDate')
  }

  get resetStartDate() {
    const startDate = this.data.get('resetStartDate')
    return startDate ? new DateTime(new Date(startDate)) : null
  }

  get resetEndDate() {
    const endDate = this.data.get('resetEndDate')
    return endDate ? new DateTime(new Date(endDate)) : null
  }

  get startDate() {
    const startDate = this.data.get('startDate')
    return startDate ? new DateTime(new Date(startDate)) : null
  }

  get endDate() {
    const endDate = this.data.get('endDate')
    return endDate ? new DateTime(new Date(endDate)) : null
  }

  get startEmpty() {
    return this.data.get('startEmpty') === "true"
  }

  get showApply() {
    return this.data.get('showApply') !== "false"
  }

  get blockStartDate() {
    return this.data.get('blockStartDate')
  }

  get lockEndDate() {
    return this.data.get('lockEndDate')
  }
}
