import 'chartkick/highcharts'
import Highcharts from 'highcharts'
import { Controller } from '@hotwired/stimulus'
import i18n from '../../initializers/i18n'

export default class Body extends Controller {
  static targets = ['fullSpinner']

  connect () {
    // Reset full spinner
    if (this.hasFullSpinnerTarget) this.fullSpinnerTarget.style.display = 'none'

    const change = this.data.get('change') === 'true'
    this.element.bodyController = this
    if (change) {
      this.setTranslations()
    }

    // Add full spinner loading for all A elements
    if (this.hasFullSpinnerTarget) {
      document.addEventListener('click', (event) => {
        const path = event.path || (event.composedPath && event.composedPath())
        let isLink = false
        // Check if an A element is present in path
        if (path) {
          const aElement = path.find(e => e?.nodeName?.toLowerCase() == 'a')
          isLink = !!aElement

          // Check that it already has a loading
          if (aElement) {
            // If already has an action, then there should a loading for it
            isLink = !aElement.hasAttribute('data-action')
          }
        }
        else {
          // Check if the target is an A element
          const nodeType = event?.target?.nodeName?.toLowerCase()
          isLink = nodeType == 'a'
        }
        
        // Ignore if not link or used with control key (opens in new tab)
        if (!isLink || event.ctrlKey) return
  
        // Show full spinner!
        this.fullSpinnerTarget.style.display = 'block'
      })
    }
  }

  // Function to update the highcharts (chart framework) locale according the rails locale
  setTranslations () {
    const locale = this.data.get('locale')
    i18n.locale = locale

    Highcharts.setOptions({
      lang: {
        months: i18n.t('date.month_names').slice(1),
        shortMonths: i18n.t('date.abbr_month_names').slice(1),
        weekdays: i18n.t('date.day_names'),
        shortWeekdays: i18n.t('date.abbr_day_names')
      },
      time: {
        useUTC: false,
        timezone: 'Europe/Lisbon'
      }
    })
  }

  get locale () {
    return this.data.get('locale')
  }
}
