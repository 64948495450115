import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import './initializers'
import './pages'

/* RAILS */
Rails.start()
Turbolinks.start()
ActiveStorage.start()
