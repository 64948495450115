import Base from '../../base'

class EventListFilters extends Base {
  setup () {
    const $sortIcons = $('.table__unit--header .table__unit i')
    const $form = $('#filters')
    const $sortParam = $form.find("input[name='sort_param']")
    const $sortDirection = $form.find("input[name='sort_direction']")

    $sortIcons.css('color', '#dadada')
    $sortIcons.hover(function () {
      $(this).css('cursor', 'pointer')
    })

    $sortParam.css('color', '141414')

    $sortIcons.each((_index, icon) => {
      const $icon = $(icon)
      if ($icon.data('sortParam') === $sortParam.val()) {
        const sortDirection = parseInt($sortDirection.val())

        $icon.css('color', '')
        if (sortDirection === -1) {
          $icon
            .removeClass('fa-sort-amount-up')
            .addClass('fa-sort-amount-down')
        }
      }
    })

    $('.table__unit--header .table__unit').click((ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      const $icon = $(ev.currentTarget).find('i')
      const iconSortParam = $icon.data('sortParam')
      const sortDirection = parseInt($sortDirection.val())
      const fullSpinner = $('.full-spinner')

      fullSpinner.css('display', 'block')
      if ($sortParam.val() === iconSortParam) {
        $sortDirection.val(sortDirection * -1)
      } else {
        $sortDirection.val(1)
      }

      $sortParam.val(iconSortParam)
      $form.submit()
    })

    $('#filter_start_date').on('dp.change', e => {
      if ($('#filter_end_date').data('DateTimePicker')) {
        $('#filter_end_date').data('DateTimePicker').minDate(e.date)
      }
    })
  }
}

export default EventListFilters
