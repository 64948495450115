import { ResizeObserver } from 'resize-observer'

export default () => {
  function stickify (selector, position, container, aboveElement) {
    const children = selector.children()
    let newWidth = container.width()

    children.css('width', newWidth)
    selector.css('top', aboveElement.clientHeight)

    // Adjust top accordingly when aboveElement changes dimensions
    new ResizeObserver(() => selector.css('top', aboveElement.clientHeight)).observe(aboveElement)

    $(window).scroll(function (e) {
      e.preventDefault()
      if ($(window).scrollTop() >= position) {
        selector.slideDown('fast')
      } else {
        selector.slideUp('fast')
      }
    })

    // add width proportional of parent
    $(window).resize(function () {
      newWidth = container.width()
      children.css('width', newWidth)
    })
  }

  if ($('.manager-nav').length > 0) {
    stickify($('.breadcrumb_nav--fixed'), 150, $('.page__container'), $('.manager-nav')[0])
  }
}
