import objectDig from 'object-dig'
import objectDeepMerge from 'deepmerge'

import svg from './index.svg'
import defaultOptions from './default_options.json'
import TooltipManager from '../tooltip_manager'

/*
  OPTIONS
  {
    width: String|Integer,
    height: String|Integer,
    hoverEnabled: Boolean,
    selectionEnabled: Boolean,
    tooltipEnabled: Boolean,
    autoTooltipHeaders: Boolean,
    stroke: {
      color: String
    },
    background: {
      color: String
    },
    unselected: {
      color: String,
      hover: {
        color: String
      }
    },
    selected: {
      color: String,
      hover: {
        color: String
      }
    },
    tooltip: {
      header: String,
      body: String
    },
    districtOptions: {
      <districtId>: {
        unselected: {
          color: String,
          hover: {
            color: String
          }
        },
        selected: {
          color: String,
          hover: {
            color: String
          }
        },
        tooltip: {
          header: String,
          body: String
        }
      }
    },
    districtNames: {
      <districtId>: String
    }
  }
*/

class DistrictsMap {
  constructor (options = {}) {
    this.options = objectDeepMerge(defaultOptions, options)
    if (this.options.autoTooltipHeaders) this.setTooltipHeaders()

    this.rootEl = null
    this.svgEl = null
    this.selectedDistrictIds = []
    this.tooltipManager = null
    this.hoveredDistrictEl = null
  }

  render (selector) {
    // Render
    this.rootEl = document.querySelector(selector)
    this.tooltipManager = new TooltipManager(document.body)
    this.rootEl.innerHTML = svg
    this.svgEl = this.rootEl.firstChild

    // Event handling
    this.svgEl.onmouseover = this.onmouseover.bind(this)
    this.svgEl.onclick = this.onclick.bind(this)
    this.onload()

    window.addEventListener('mouseover', ev => {
      // If away from the district hide the tooltip
      // The exception is if it is shown by a legend, without following the mouse
      if (!ev.target.closest(selector) && this.tooltipManager.followMouse) {
        this.tooltipManager.hide()
        this.unhoverDistrictEl(this.hoveredDistrictEl)
      }
    })
  }

  onload () {
    this.svgEl.setAttribute('fill', this.options.background.color)
    this.svgEl.setAttribute('stroke', this.options.stroke.color)
    const rects = this.svgEl.getElementsByClassName('islandBorder')
    for (const rect of rects) {
      rect.setAttribute('stroke', this.options.islandBorderStroke.color)
    }

    if (this.options.width !== undefined) this.svgEl.setAttribute('width', this.options.width)
    this.svgEl.setAttribute('height', this.options.height)

    Object.keys(this.options.districtOptions || {}).forEach(districtId => {
      const districtEl = document.querySelector(`path[data-district-id='${districtId}']`)
      districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'unselected', 'hover', 'color'))
    })
  }

  onmouseover (e) {
    const [_districtEl, districtId] = this.getDistrictEl(e.target)
    this.showTooltipDistrict(districtId, true)
  }

  /**
  * Shows the tooltip for a district in its zone without hovering on the district
  * @param {string} districtId - the ID of the district
  */
  showTooltipDistrict (districtId, followMouse = false) {
    if (this.options.hoverEnabled) {
      this.unhoverDistrictEl(this.hoveredDistrictEl)
      const districtEl = this.getDistrictElById(districtId)
      if (districtEl != null) {
        if (this.selectedDistrictIds.includes(districtId)) {
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'selected', 'hover', 'color'))
        } else {
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'unselected', 'hover', 'color'))
        }

        this.hoveredDistrictEl = districtEl

        if (this.options.tooltipEnabled) {
          this.tooltipManager.hide()

          this.tooltipManager.show(
            districtEl,
            this.getDistrictOption(districtId, 'tooltip', 'header'),
            this.getDistrictOption(districtId, 'tooltip', 'body'),
            followMouse
          )
        }
      } else if (districtId === null) {
        this.tooltipManager.hide()
      }
    }
  }

  unhoverDistrictEl (districtEl) {
    if (this.hoveredDistrictEl) {
      const districtId = districtEl.dataset.districtId

      if (districtId != null) {
        if (this.selectedDistrictIds.includes(districtId)) {
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'selected', 'color'))
        } else {
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'unselected', 'color'))
        }
      }
    }
  }

  onclick (e) {
    const [districtEl, districtId] = this.getDistrictEl(e.target)

    if (districtId != null) {
      if (this.options.selectionEnabled) {
        if (this.selectedDistrictIds.includes(districtId)) {
          this.selectedDistrictIds = this.selectedDistrictIds.filter(id => id !== districtId)
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'unselected', 'color'))
        } else {
          this.selectedDistrictIds.push(districtId)
          districtEl.setAttribute('fill', this.getDistrictOption(districtId, 'selected', 'color'))
        }
      }
    }
  }

  getDistrictEl (element) {
    return [
      element.tagName === 'rect' ? element.nextElementSibling : element,
      element.dataset.districtId
    ]
  }

  getDistrictElById (districtId) {
    return document.querySelector(`path[data-district-id='${districtId}']`)
  }

  getDistrictOption (districtId, ...optionPath) {
    return objectDig(this.options, 'districtOptions', districtId, ...optionPath) || objectDig(this.options, ...optionPath)
  }

  setTooltipHeaders () {
    for (const districtId in this.options.districtNames) {
      if (!objectDig(this.options, 'districtOptions', districtId, 'tooltip', 'header')) {
        this.options.districtOptions = objectDeepMerge(
          this.options.districtOptions,
          {
            [districtId]: { tooltip: { header: this.options.districtNames[districtId] } }
          }
        )
      }
    }
  }
}

export default DistrictsMap
export { defaultOptions }
