import { setupTableStickyHeaders, setupBreadcrumbsSticky } from '../../../services'
import EventSessions from '../partials/event_sessions'

$(document).on('turbolinks:load', () => {
  if ($('#events-sales-evolution').length !== 0) {
    new EventSessions().setup()

    $(document).on('sales_by_period_typology_table_loaded', setupTableStickyHeaders)
    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
