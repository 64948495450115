import EventSessions from '../partials/event_sessions'
import { setupBreadcrumbsSticky } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#events-sales-by-discount').length !== 0) {
    new EventSessions().setup()
    // Set sticky breadcrumb
    setupBreadcrumbsSticky()
  }
})
