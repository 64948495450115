import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["modal"]

  connect () {
    this.show()
  }

  show () {
    this.modalTarget.style.display = 'block'
  }

  hide (event) {
    this.modalTarget.style.display = 'none'
    event.preventDefault()
  }
}
