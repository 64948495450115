import i18n from "./initializers/i18n";

export const eventsMultiSelectUpdater = (select) => {
    if($(select)[0].multiple === false) return
    const selectId = $(select).attr('id');
    const ulDiv = $(select).siblings('span.select2').find('ul')
    const count = $(select).select2('data').length
    const id = $(select).select2('data')[0]?.id;
    if (id !== '' && count > 1) {
        const item = count === 1 ? `views.events.selects.${selectId}.singular` : `views.events.selects.${selectId}.plural`
        const selected = count === 1 ? "views.events.selects.selected" : "views.events.selects.selected_plural"
        ulDiv.html("<li class='select2-selection__choice select2-selection__counter'>"
            + count + " " + i18n.t(item) + " " + i18n.t(selected) + "</li>")
    }
}