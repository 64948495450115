import { Controller } from '@hotwired/stimulus'
import Vivus from 'vivus'

export default class extends Controller {
  connect () {
    const svgElement = this.element.querySelector('svg')

    if (!svgElement) return // There is no svg inside

    new Vivus(
      this.element.querySelector('svg'),
      {
        duration: 120,
        start: 'autostart',
        animTimingFunction: Vivus.EASE
      },
      function (vivus) {
        if (vivus.getStatus() === 'end') {
          vivus.reset().play()
        }
      }
    )
  }
}
