import Base from '../../base'

class NonBlockedOccupationLogic extends Base {
  setup () {
    const selector = document.querySelector('[selector="non_blocked_occupation_selector"]')

    if (!selector) return

    const showCorrect = (isTotalOccupation) => {
      document.querySelectorAll('[selector="total_occupation_stats"]')
        .forEach(el => (el.style.display = isTotalOccupation ? '' : 'none'))
      document.querySelectorAll('[selector="non_blocked_occupation_stats"]')
        .forEach(el => (el.style.display = !isTotalOccupation ? '' : 'none'))

      const field = document.querySelector('[selector="non_blocked_occupation_field"]')
      if (field) field.value = !isTotalOccupation

      // Update paginators url
      if (field) {
        const param = field.getAttribute('name')
        document.querySelector('[selector="events-search-controller"]')?.controller?.updatePaginatorURL(param, !isTotalOccupation)
      }
    }

    // Check if previously defined
    showCorrect(!selector.checked)

    selector.addEventListener('click', () => {
      const isTotalOccupation = !selector.checked

      // Inform backend if necessary
      const url = `${selector.getAttribute('data-url')}?checked=${selector.checked}`
      if (url) fetch(url)

      // Set displays
      showCorrect(isTotalOccupation)
    })
  }
}

export default NonBlockedOccupationLogic
