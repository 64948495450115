import Base from '../../base'

class EventFilters extends Base {
  setup () {
    $('#filter_start_date').on('dp.change', e => {
      if ($('#filter_end_date').data('DateTimePicker')) {
        $('#filter_end_date').data('DateTimePicker').minDate(e.date)
      }
    })

    $('#sessions_filter_start_date').on('dp.change', e => {
      if ($('#sessions_filter_end_date').data('DateTimePicker')) {
        $('#sessions_filter_end_date').data('DateTimePicker').minDate(e.date)
      }
    })
  }
}

export default EventFilters
