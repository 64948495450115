import i18n from "../initializers/i18n";

const changeToCount = (context) => {

  const ulDiv = $(context).siblings('span.select2').find('ul')
  if(!$(context).select2('data')) return;

  const count = $(context).select2('data').length
  const firstId = $(context).select2('data')[0].id ?? null;
  if(firstId === '') return;
  if (count > 1) {
    const entity = "views.general.dashboard.selects.entities"
    const selected = "views.general.dashboard.selects.selected_plural_gendered"
    ulDiv.html("<li class='select2-selection__choice select2-selection__counter'>"
        + count + " " + i18n.t(entity) + " " + i18n.t(selected) + "</li>")
  }
}

export default () => {
  const contextForm = $('[selector="context-filter-form"]')
  const contextSelect = $('[selector="context-filter-select"]')
  const fullSpinner = $('.full-spinner')

  if(!contextSelect) return;

  contextSelect.select2({ width: null })
  // Force to set the Include blank option
  if (contextSelect.length && !contextSelect.val().length){
    contextSelect.val(null).trigger('change')
  }
  // Store previous selected values
  contextSelect.data('data-prev', contextSelect.val())

  changeToCount(contextSelect)

  // Context filter on change
  contextSelect.on('change', () => {
    const options = Array.from(contextSelect.get(0).options)
    const previousOption = contextSelect.data('data-prev')
    let selectedOptions = Array.from(contextSelect.get(0).selectedOptions)?.map(option => option.value)

    if (!selectedOptions.length) {
      selectedOptions = ['']
    // Prevents to do this logic in case we remove an selected element
    } else if (previousOption.length <= selectedOptions.length) {
      const newOption = selectedOptions.filter(value => !previousOption.includes(value))[0]
      const venueRoom = newOption?.split('_')

      if (venueRoom[0] === '') {
        selectedOptions = ['']
      } else if (venueRoom[0] !== '' && venueRoom[1] === '') {
        options.forEach((element) => {
          if (element.value.includes(venueRoom[0]) && !selectedOptions.includes(element.value)) {
            selectedOptions.push(element.value)
          }
        })
      }
    }

    if (selectedOptions.includes('') && selectedOptions.length > 1){
      selectedOptions = selectedOptions.filter((el) => el !== '')
    }
    const allOptionsSelected = selectedOptions.length === (options.length - 1);

    if (allOptionsSelected) {
      selectedOptions = ['']
    }

    contextSelect.val(selectedOptions).trigger('change.select2')
    changeToCount(contextSelect)
    fullSpinner.css('display', 'block')
    contextForm.submit()
  })
}
